import React, { useState, useEffect } from 'react';

import Layout from "../../components/layout"


const Index = () => {


    return (
        <Layout>
            <h1>Preview</h1>
        </Layout>
    )
}

export default Index
